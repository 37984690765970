import * as React from 'react';
import animatedIntroMp4 from "../videos/TOSSAnimatedCompressed.mp4";
import animatedIntroWebm from "../videos/TOSSAnimatedCompressed.webm";

export function IntroVideo() {
    const videoRef = React.useRef(null);

    const [isPlaying, setIsPlaying] = React.useState(false);

    const togglePlay = () => {
        const video = videoRef.current;
        if (!video) return;

        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div id='introVideo'>
            <div className='introVideoContainer'>
                <video
                    ref={videoRef}
                    width="70%"
                    controls={isPlaying}
                    style={{ background: "black" }}
                    preload="metadata"
                >
                    <source src={animatedIntroMp4} type='video/mp4' />
                    <source src={animatedIntroWebm} type='video/webm' />
                    Your browser does not support the video tag.
                </video>
                {!isPlaying && (
                <button className="playVideoButton" onClick={togglePlay}>
                    <svg width='80px' height='80px' fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" href="http://www.w3.org/1999/xlink" viewBox="0 0 408.221 408.221"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M204.11,0C91.388,0,0,91.388,0,204.111c0,112.725,91.388,204.11,204.11,204.11c112.729,0,204.11-91.385,204.11-204.11 C408.221,91.388,316.839,0,204.11,0z M286.547,229.971l-126.368,72.471c-17.003,9.75-30.781,1.763-30.781-17.834V140.012 c0-19.602,13.777-27.575,30.781-17.827l126.368,72.466C303.551,204.403,303.551,220.217,286.547,229.971z"></path> </g> </g> </g></svg>
                </button>
                )}
            </div>
        </div>
    );
}
